import React from "react";

import LandingPage from "../pages/LandingPage/landingPage";
import MainLayouts from "../Layout/mainLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TermOfService from "../pages/TermOfService/TermOfService";

const RoutData = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <MainLayouts>
                <LandingPage />
              </MainLayouts>
            }
          ></Route>
          <Route
            path="/terms_of_service"
            element={
              <MainLayouts>
                <TermOfService />
              </MainLayouts>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutData;
