import React, { useState } from "react";
import plus from "../../images/plus.png";
import minus from "../../images/minus.png";
import play from "../../images/playstoreIcon.png";
import apple from "../../images/Apple_logo.png";
import apple2 from "../../images/AppleWhite.png";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import sec7 from "../../images/sec7.png";

export default function TermOfService() {
  const [openIndex, setOpenIndex] = useState(null); // State to track the currently open accordion
  const [src1, setSrc1] = useState(apple);
  const [src, setSrc] = useState(apple);
  let [isOpen, setIsOpen] = useState(false);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const faqData = [
    {
      question: "What is Renko?",
      answer:
        "Renko is a convenient app that brings together all your service, property, and job needs on one platform.",
    },
    {
      question: "How does Renko make finding properties easier?",
      answer:
        "Our extensive listing of properties with detailed information, photos, and contact details of agents simplifies the process of buying, renting, or leasing property.",
    },
    {
      question: "Can I connect with service providers through Renko?",
      answer:
        "Yes, Renko provides a platform for you to connect with various service providers, from plumbers to personal trainers.",
    },
    {
      question:
        "Is it safe to use Renko's chat feature when communicating with service providers?",
      answer:
        "Absolutely! Renko prioritizes user safety and ensures secure and private chats between users.",
    },
    {
      question: "How does payment work on Renko?",
      answer:
        "When you accept an offer, payment is securely held until the order is completed, giving both parties peace of mind.",
    },
    {
      question: "Are service providers on Renko trustworthy?",
      answer:
        "Our trusted ratings system and user reviews contribute to creating a transparent and reliable community within Renko.",
    },
    {
      question: "Can I sell items on Renko?",
      answer:
        "Yes, selling has never been easier with our customizable listings and easy-to-use interface.",
    },
    {
      question: "Does Renko keep users updated on important notifications?",
      answer:
        "Yes, our app provides personalized and real-time notifications to keep you informed about important updates.",
    },
    {
      question: "Is Renko available in my local community?",
      answer:
        "Renko brings together a community of outstanding service providers and neighborhoods, making it likely that we are available in your area.",
    },
    {
      question: "Can I find job opportunities on Renko?",
      answer:
        "Definitely! Renko offers a diverse range of job opportunities, from full-time positions to internships, making it easy for you to find your dream job. Additionally, our app is constantly updating with the latest property listings, so you never miss out on finding your perfect home.",
    },
  ];
  return (
    <>
      <div className="footer pt-40 ">
        <div className="text-white pb-9 text-center">
          <p className="text-4xl pb-8 font-semibold">Term Of Service</p>
          <p className="text-xl font-normal">Effective Date: 16 May 2024</p>
        </div>
      </div>
      <div className="2xl:px-[80px] xl:px-[60px] md:px-[50px] lg:px-[50px] sm:px-[30px] px-[20px] sm:pt-32 pt-10">
        <section className="2xl:px-[250px] xl:px-[220px] md:px-[80px] lg:px-[150px] px-0 sm:pb-32 pb-10">
          <div className="2xl:text-[22px] xl:text-xl font-normal">
            <p>
              Technology has taken over the world - with words like Artificial
              Intelligence, Machine Learning, Big Data and Blockchain Technology
              gaining mainstream popularity from once being considered
              appropriate for only a highly specialised space for engineers and
              scientists. But this paradigm shift has been welcomed by anyone
              and everyone - especially as these technologies have been
              transforming our everyday professional, and often our personal
              lives as well, which is very widely noted in the recruitment
              sectors of every industry in 2024. Easily accessible Generative AI
              products such as ChatGPT and Google Bard has made it much easier
              for candidates to craft their resumes according to the automated
              screening models deployed by various Applicant Tracking Systems
              (ATS), which in turn made it that more difficult for recruiters to
              actually understand which candidates are the most qualified for
              the position they are hiring for. This modern dilemma for
              recruiters has presented a modern debate among industry experts -
              how to navigate their recruitment process weighing for skills and
              experiences - resumes versus assessments.
            </p>
            <p className="pt-10">
              Which is exactly what we will try to tackle through this piece -
              Skills vs. Experience: What Matters More While Assessing
              Candidates in 2024; A fundamental insight to hiring for the
              future.
            </p>
          </div>
          <div className="pt-10">
            <div className="text-3xl font-medium flex items-center gap-3 pb-6">
              <p>|</p>
              <p>Rights to Use the Services</p>
            </div>
            <ul className="list-disc 2xl:text-[22px] xl:text-xl  ms-5">
              <li className="pb-5">
                Provided that the Agreement's terms and conditions, including
                the payment of applicable fees, are adhered to, TestTalents
                allows you to access and use the Services under a limited,
                personal, non-exclusive, non-sublicensable, non-transferable,
                and non-assignable license. This license is granted solely for
                the purpose of your internal business operations.
              </li>
              <li className="pb-5">
                The Services' access and usage are extended to a specific number
                of individuals, referred to as "Candidates," who have been duly
                authorized by you as the Customer in accordance with the rights
                outlined in the Agreement and as specified in the Order Form.
              </li>
              <li>
                If approved in writing by TestTalents, your Affiliates may also
                use the Services without requiring a separate Order Form. To
                enable their access, you can provide your Affiliates with login
                credentials. It is essential to note that your Affiliates' usage
                is subject to the terms of the Agreement, and you hold direct
                responsibility for their access and use of the Services. In
                these Terms, any reference to "you" includes your Affiliates.
              </li>
            </ul>
          </div>
          <div className="pt-8">
            <p className="text-3xl font-medium pb-6">
              Why Experience Matters When Hiring
            </p>
            <div className="pb-8">
              <p className="2xl:text-[22px] xl:text-xl  font-semibold pb-3">
                Industry Knowledge and Exposure:
              </p>
              <p className="2xl:text-[22px] xl:text-xl ">
                Where a candidate has worked previously, the company and the
                industry, can provide a unique degree of knowledge and exposure
                into how that industry works in general, specific nuances of the
                industry in the specific geographic location, and the key
                factors of success within that segment. These factors can
                contribute to the potential effectiveness and efficiency of the
                candidate, given their experiences align with the current
                position they are applying for. These benefits are more acutely
                impactful in certain industries than others - such as finance,
                healthcare, advertising and so on.
              </p>
            </div>
            <div className="pb-8">
              <p className="2xl:text-[22px] xl:text-xl  font-semibold pb-4">
                Historical Performance Indicators:
              </p>
              <p className="2xl:text-[22px] xl:text-xl ">
                While past experience alone may not guarantee future success, it
                is a strong indication of the candidate’s track record and
                career trajectory. The duration of employment in different
                organisations in the past, whether the candidate received any
                promotions during their tenure, the kind of companies the
                candidate has worked in - all of these can provide valuable
                insights into the candidate’s aptitude and approach to work -
                which is invaluable to a recruiter and definitely needs a pair
                of human eyes to be gauged properly.
              </p>
            </div>
            <div className="">
              <p className="2xl:text-[22px] xl:text-xl  font-semibold pb-4">
                Cultural Fit and Organisational Alignment:
              </p>
              <p className="2xl:text-[22px] xl:text-xl ">
                With a background of a candidate’s past work experience,
                educational background and other activities can help recruiters
                understand the potential of a good cultural fit against a
                difficult one. Candidates who are more aligned with the company
                culture and organisational approaches may require much less time
                to acclimate to the internal dynamics of the organisation and
                the team, and thus can enhance within team cohesion and synergy
                - quickly reflecting in a productivity boost along with a high
                potential of longer term employee retention.
              </p>
            </div>
          </div>
          <div className="pt-8">
            <p className="text-3xl font-medium pb-6">
              Finding the Balance: How to Build a Holistic Hiring Process
            </p>
            <div className="pb-8">
              <p className="2xl:text-[22px] xl:text-xl ">
                Rather than viewing skills and experience as mutually exclusive,
                organisations should strive to strike a balance that
                acknowledges the complementary nature of these attributes. By
                leveraging skills assessments and behavioural interviews,
                employers can gain a more nuanced understanding of candidate
                suitability while mitigating the risk of bias associated with
                experience-based hiring practices.
              </p>
            </div>
            <div className="pb-8">
              <p className="2xl:text-[22px] xl:text-xl ">
                Rather than viewing the two qualifying factors of skills and
                experience in competing terms, organisations would rather
                benefit by striving to strike a balance as these complement one
                another to find the right person for the right job. 
              </p>
            </div>
            <div className="pb-8">
              <p className="2xl:text-[22px] xl:text-xl ">
                In Bangladesh, the predominant hiring strategies rarely include
                proper skill-testing, and the rare ones which attempt this find
                it very costly to implement effectively. Most of these companies
                attempt manual testing - which involves physical room
                allocation, managing the entire test process by their employees,
                grading the test by internal employees who are specialists, both
                consuming valuable work-hours which can be spent elsewhere to
                bring value to the organisation.
              </p>
            </div>
            <div className="">
              <p className="2xl:text-[22px] xl:text-xl ">
                Automated pre-assessment tests like TestTalents can
                significantly make this process easier, cost-effective and time
                saving. By implementing TestTalents, organisations can automate
                their candidate hiring process while testing for skills, while
                also accounting for experience in their hiring workflow. After
                an initial screen of resumes, it is easy to send the initially
                qualified candidates an online assessment from our existing
                library of 400+ assessments, with the option to add customised
                questions in multiple formats, according to the needs presented
                by the role. The assessments are then automatically graded and
                presented alongside the resumes for a final short-list before
                scheduling the interviews with the line managers or field
                specialists. By significantly reducing cost and time invested
                into the process, TestTalents enables skill-based hiring to
                ensure the right candidate is chosen for the right role.
              </p>
            </div>
          </div>
          <div className="pt-8">
            <p className="text-3xl font-medium pb-6">
              Why You Should Choose Skills-First Hiring
            </p>
            <p className="2xl:text-[22px] xl:text-xl  pb-8">
              In a competitive talent landscape, adopting a skills-based hiring
              approach offers several compelling advantages:
            </p>
            <div className="pb-8">
              <p className="2xl:text-[22px] xl:text-xl  font-semibold pb-3">
                Enhanced Predictive Accuracy:
              </p>
              <p className="2xl:text-[22px] xl:text-xl ">
                Research indicates that skills-based hiring is over twice as
                effective in predicting job performance compared to
                experience-based hiring [3]. By prioritising skills, employers
                can make more informed hiring decisions that align with their
                organisational objectives.
              </p>
            </div>
            <div className="pb-8">
              <p className="2xl:text-[22px] xl:text-xl font-semibold pb-4">
                Expanded Talent Pool:
              </p>
              <p className="2xl:text-[22px] xl:text-xl ">
                Removing rigid experience requirements broadens the talent pool
                and fosters inclusivity by accommodating candidates with diverse
                backgrounds and skill sets. This diversification enriches the
                organisational culture and promotes innovation and creativity
                [4].
              </p>
            </div>
            <div className="">
              <p className="2xl:text-[22px] xl:text-xl  font-semibold pb-4">
                Future-proofing Organisational Resilience:
              </p>
              <p className="2xl:text-[22px] xl:text-xl ">
                As industries undergo rapid transformation and job roles evolve,
                prioritising skills over experience positions organisations to
                adapt and thrive amidst change. Skills-first hiring practices
                equip companies with the agility and versatility needed to
                navigate uncertain market dynamics.
              </p>
            </div>
          </div>
        </section>

        <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-10">
          <div className="w-100 hero md:rounded-xl rounded-3xl 2xl:h-[635px] xl:h-[500px] lg:h-[400px] md:h-[275px]">
            <div className="2xl:px-[80px] xl:px-[60px] md:px-[50px] lg:px-[50px] sm:px-[30px] px-[20px] pt-14 sm:pb-0 pb-0 p-[20px] grid grid-cols-12 items-center">
              <div className="col-span-12 md:col-span-6 pb-5">
                <h1 className="text-center text-white md:text-left 2xl:text-[64px] xl:text-[60px] lg:text-[40px] md:text-[25px] text-[36px] text-[30px] 2xl:leading-[80px] xl:leading-[72px] lg:leading-[50px] md:leading-[35px] leading-[45px] lg:font-[600] md:font-[500] sm:font-[600]">
                  Get Discovered Locally – Sell with Renko Now
                </h1>

                <div className="flex md:gap-3 gap-5 lg:gap-5 mt-8 md:justify-start justify-center rounded-lg">
                  <a
                    href="https://apps.apple.com/in/app/renko-partners/id1672284016"
                    target="_blank"
                  >
                    <div
                      className="rounded-lg hover:bg-black py-2 px-3 bg-white relative hover:text-white text-black absolute"
                      onMouseOver={() => setSrc1(apple2)}
                      onMouseLeave={() => setSrc1(apple)}
                    >
                      <div className="flex items-center gap-1 relative">
                        <img src={src1} className="w-6 h-6" />
                        <div className="">
                          <p className="text-xs md:text-[10px] lg:text-xs">
                            Download on the
                          </p>
                          <p className="font-semibold xl:text-base md:text-sm sm:text-xl text-base">
                            App Store
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <img
                    src={play11}
                    className="2xl:w-[198px] xl:w-[150px] lg:w-[120px] md:w-[130px] sm:w-[198px] w-[162px] 2xl:h-[63px] xl:h-[55px] lg:h-[45px] md:h-[45px] sm:h-[65px] h-[49px]"
                  /> */}
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.colludprovider.app&pcampaignid=web_share"
                    target="_blank"
                  >
                    <div className="rounded-lg hover:bg-black py-2 px-3 bg-white relative hover:text-white text-black absolute">
                      <div className="flex items-center gap-1 relative">
                        <img src={play} className="w-6 h-6" />
                        <div className="">
                          <p className="text-xs md:text-[10px] lg:text-xs">
                            GET IT ON
                          </p>
                          <p className="font-semibold xl:text-base md:text-sm sm:text-xl text-base">
                            Google Play
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <img
                    src={play}
                    className="2xl:w-[198px] xl:w-[150px] lg:w-[120px] md:w-[130px] sm:w-[198px] w-[162px] 2xl:h-[63px] xl:h-[55px] lg:h-[45px] md:h-[45px] sm:h-[65px] h-[49px] rounded-lg"
                  /> */}
                  </a>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:ms-[54px] flex justify-center md:justify-end">
                <img
                  src={sec7}
                  className="2xl:h-[580px] xl:h-[445px] lg:h-[345px] md:h-[220px] sm:h-[550px] h-[365px] "
                />
              </div>
            </div>
          </div>
        </div>
        <section id="faqs" className="scroll-mt-[80px]">
          <div className="2xl:my-24 xl:my-20 lg:my-16 md:my-14 my-10 ">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6">
                <h1 className="font-semibold text-center md:text-start 2xl:text-[44px] xl:text-[36px] lg:text-[35px] md:text-[30px] sm:text-[30px] text-[20px] md:leading-[35px] 2xl:leading-[53px] xl:text-[40px] text-[28px] leading-[36px] xl:leading-[45px] pb-6 pt-5">
                  FAQs
                </h1>
                <p className="text-base md:text-lg lg:text-lg xl:text-md 2xl:text-lg  text-center md:text-start  text-[#475467]  pb-10">
                  Want to know more? You can email us anytime at
                  <span>
                    <a
                      href="mailto:support@renkoapp.com"
                      className="text-sky-500 font-semibold"
                    >
                      {" "}
                      support@renkoapp.com
                    </a>
                  </span>
                </p>
                <div className="flex md:justify-start justify-center items-center pb-3">
                  <button
                    className="py-[13px] px-12 bg-[#0F0E13] text-white rounded-xl text-base md:text-lg lg:text-lg xl:text-md 2xl:text-lg "
                    type="button"
                    onClick={() => setIsOpen(true)}
                  >
                    Let's start today
                  </button>
                </div>
                <p className="md:text-start text-center font-[500] text-base">
                  Sign up to win a month FREE
                </p>
              </div>
              <div className="col-span-12 md:col-span-6">
                {faqData.map((faq, index) => (
                  <div className="p-4 border-2 rounded-xl my-5" key={index}>
                    <div
                      className="group grid grid-cols-12 items-center cursor-pointer"
                      onClick={() => handleToggle(index)}
                    >
                      <span className="text-lg text-start font-medium text-black group-hover:text-black/80 col-span-11">
                        {faq.question}
                      </span>
                      <span className="text-2xl text-[#00987C] col-span-1 flex items-center justify-end">
                        {openIndex === index ? (
                          <img src={minus} className="w-5" alt="minus" />
                        ) : (
                          <img src={plus} className="w-5" alt="plus" />
                        )}
                      </span>
                    </div>

                    {/* Show the answer if this FAQ is open */}
                    {openIndex === index && (
                      <div className="mt-2 text-base text-gray-500">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black/[.8]">
            <DialogPanel className="max-w-lg space-y-4 p-auto sm:p-12">
              <DialogTitle className="font-bold"></DialogTitle>
              <Description>
                {/* <div className="flex gap-4">
                <button
                  className="bg-black text-white rounded-lg p-2 "
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-black text-white rounded-lg p-2 "
                  onClick={() => setIsOpen(false)}
                >
                  Deactivate
                </button>
              </div> */}
                <div className="mb-20">
                  <div className="mb-7 text-white">
                    <p className="text-lg font-semibold">For Everyone</p>
                    <p className="text-sm font-light">
                      Discover everything local
                    </p>
                  </div>
                  <div className="flex sm:gap-5 gap-4 mt-8 md:justify-start justify-center rounded-lg">
                    <a
                      href="https://apps.apple.com/in/app/renko/id1669458896"
                      target="_blank"
                    >
                      <div
                        className="rounded-lg hover:bg-black py-2 px-3 bg-white relative hover:text-white text-black absolute"
                        onMouseOver={() => setSrc(apple2)}
                        onMouseLeave={() => setSrc(apple)}
                      >
                        <div className="flex items-center gap-3 relative">
                          <img src={src} className="w-8 h-8" />
                          <div className="">
                            <p className="text-[10px] sm:text-xs">
                              Download on the
                            </p>
                            <p className="font-semibold xl:text-xl md:text-base sm:text-base text-sm">
                              App Store
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.collud.app&pcampaignid=web_share"
                      target="_blank"
                    >
                      <div className="rounded-lg hover:bg-black py-2 px-3 bg-white relative hover:text-white text-black absolute">
                        <div className="flex items-center gap-3 relative">
                          <img src={play} className="w-8 h-8" />
                          <div className="">
                            <p className="text-[10px] sm:text-xs">GET IT ON</p>
                            <p className="font-semibold xl:text-xl md:text-base sm:text-base text-sm">
                              Google Play
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="">
                  <div className="mb-7 text-white">
                    <p className=" text-lg font-semibold">For business</p>
                    <p className="text-sm font-light ">
                      Manage and Expand your business
                    </p>
                  </div>
                  <div className="flex sm:gap-5 gap-4 mt-8 md:justify-start justify-center rounded-lg">
                    <a
                      href="https://apps.apple.com/in/app/renko-partners/id1672284016"
                      target="_blank"
                    >
                      <div
                        className="rounded-lg hover:bg-black py-2 px-3 bg-white relative hover:text-white text-black absolute"
                        onMouseOver={() => setSrc1(apple2)}
                        onMouseLeave={() => setSrc1(apple)}
                      >
                        <div className="flex items-center gap-3 relative">
                          <img src={src1} className="w-8 h-8" />
                          <div className="">
                            <p className="text-xs">Download on the</p>
                            <p className="font-semibold xl:text-xl md:text-base sm:text-base text-sm">
                              App Store
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.colludprovider.app&pcampaignid=web_share"
                      target="_blank"
                    >
                      <div className="rounded-lg hover:bg-black py-2 px-3 bg-white relative hover:text-white text-black absolute">
                        <div className="flex items-center gap-3 relative">
                          <img src={play} className="w-8 h-8" />
                          <div className="">
                            <p className="text-xs">GET IT ON</p>
                            <p className="font-semibold xl:text-xl md:text-base sm:text-base text-sm">
                              Google Play
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Description>
            </DialogPanel>
          </div>
        </Dialog>
      </div>
    </>
  );
}
