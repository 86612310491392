import React, { useEffect, useState } from "react";
import logo from "../../images/logo1.png";
import { useLocation } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { IoIosMenu } from "react-icons/io";
import { Link } from "react-router-dom";
export default function Header() {
  const [isActive, setIsActive] = useState(false);
  const { hash } = useLocation();

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <div className="bg-white 2xl:px-[80px] xl:px-[60px] md:px-[50px] lg:px-[50px] sm:px-[30px] px-[15px] py-5 flex justify-between items-center fixed top-0 w-full z-10">
        <div className="flex items-center gap-x-3">
          <img src={logo} alt="logo" className="h-14" />
          <h1 className="font-extrabold text-xl hidden lg:flex ">Renko</h1>
        </div>
        <div className="flex items-center gap-x-10 hidden lg:flex">
          <Link className=" text-base hidden lg:flex font-normal" to="/#home">
            Home
          </Link>
          <Link
            className=" text-base hidden md:flex  font-normal"
            to="/#service"
          >
            Product tour
          </Link>
          <Link
            className=" text-base hidden md:flex  font-normal"
            to="/#feature"
          >
            Feature
          </Link>
          <Link className=" text-base hidden md:flex  font-normal" to="/#price">
            Price
          </Link>
          <Link className=" text-base hidden md:flex font-normal" to="/#faqs">
            FAQs
          </Link>
        </div>
        <Link to="/#price">
          <button
            className="rounded-lg bg-black text-white py-3 px-5 items-center hidden lg:block text-base"
            type="button"
          >
            Let's Start Today
          </button>
        </Link>
        <IoIosMenu
          className="size-9 align-middle visible lg:hidden"
          onClick={toggleSidebar}
        ></IoIosMenu>
        <div
          className={`fixed inset-y-0 left-auto right-0 transform ${
            isActive ? "-translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out bg-white z-10 sm:w-96 w-8/12 z-30 visible lg:hidden`}
        >
          <div className="flex items-center px-4 py-5">
            <button className="focus:outline-none" onClick={toggleSidebar}>
              {/* <img src={close} alt="close" className="w-5 h-5"></img> */}
              <IoIosClose className="size-10" />
            </button>
          </div>
          <nav className="px-4 py-2 mt-5">
            <ul className="space-y-5 font-medium text-base">
              <li>
                <Link className=" text-base  font-normal" to="/#home">
                  Home
                </Link>
              </li>
              <li>
                <Link className=" text-base   font-normal" to="/#service">
                  Product tour
                </Link>
              </li>
              <li>
                <Link className=" text-base   font-normal">Feature</Link>
              </li>
              <li>
                <Link className=" text-base   font-normal" to="/#price">
                  Price
                </Link>
              </li>
              <li>
                <Link className=" text-base  font-normal" to="/#faqs">
                  FAQs
                </Link>
              </li>
            </ul>
            <Link to="#price">
              <button
                className="rounded-lg bg-black text-white mt-10 py-3 text-base px-5 items-center"
                type="button"
              >
                Let's Start Today
              </button>
            </Link>
          </nav>
        </div>
      </div>
    </>
  );
}
